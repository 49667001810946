<template>
  <div class="top-header">
    <div>
      <div class="platform-wrapper">
        <img
          @click="restoreDefault"
          src="../../../src/assets/xinjiangImage/service-platform-img.png"
          alt=""
        />
      </div>
      <div>
        <div class="login-register-wrapper">
          <el-dropdown
            v-if="!headerShow"
            class="avatar-container right-menu-item hover-effect"
            trigger="click"
          >
            <div class="avatar-wrapper">
              <span>机构管理</span>
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="downloadAuthFile">
                <span>下载客户信息查询和使用授权书模板</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="downloadDealFile">
                <span>下载金融机构和新疆征信服务协议模板</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="uploadCustomerInfoAndAuth">
                <span>上传客户信息查询和使用授权书</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="uploadFinanceDual">
                <span>上传金融机构和新疆征信服务协议</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <p @click="goProductList">产品中心</p>
          <p @click="becomeDeveloper">成为开发者</p>
          <el-dropdown
            class="avatar-container right-menu-item hover-effect"
            trigger="click"
          >
            <div class="avatar-wrapper">
              <span>特色中心</span>
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu
              slot="dropdown"
              class="character-dropdown-wrapper"
            >
              <!-- <el-dropdown-item>
                <a href="https://www.xj-zhengxin.com/cj_web/" target="_blank"
                  >数据采集中心</a
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="https://www.xj-zhengxin.com/gl_web/" target="_blank"
                  >数据管理中心</a
                >
              </el-dropdown-item> -->
              <el-dropdown-item>
                <a href="https://www.xj-zhengxin.com/zhjrfw" target="_blank"
                  >企业综合金融服务中心</a
                >
                <!--                                    <a href="http://220.171.134.140:17080/zhjrfw/" target="_blank">企业综合金融服务中心</a>-->
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <p @click="showpublicMessageclick">公开信息</p>
          <div class="login-register-btn" v-if="headerShow">
            <el-button @click="dialogVisibleShow"
              >登录&nbsp;/&nbsp;注册</el-button
            >
          </div>
          <el-dropdown
            v-else
            @command="handleCommand"
            class="login-register-btn"
          >
            <img
              v-if="userData.headImg == null"
              @click="goUser"
              style="width: 64px; height: 64px; cursor: pointer"
              src="../../../public/static/image/avatar.png"
            />
            <img
              v-else
              @click="goUser"
              style="width: 64px; height: 64px; cursor: pointer"
              :src="this.$getImageUrl(userData.headImg)"
              :onerror="this.$defaultimg"
            />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  props: {
    headerShow: {
      type: Boolean,
    },
    zIndex: {
      type: Number,
    },
    userData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  methods: {
    restoreDefault() {
      console.log(this.$router.currentRoute, "this.$router.currentRoute");
      if (this.$router.currentRoute.path != "/") {
        this.$router.push("/");
      } else {
        this.$emit("restoreDefault");
      }
    },

    // 下载客户信息查询和使用授权书模板
    downloadAuthFile() {
      let data = {
        enterpriseCode: "enterpriseCode",
        queryData: "",
        pdfLocation: "pdfLocation",
        enterpriseName: "enterpriseName",
        userName: "userName",
        orgCode: "orgCode",
      };

      this.$axios.post("/bank/getBankEntPDF", data).then((res) => {
        if (res.data.data.file) {
          let authFile = "data:application/pdf;base64," + res.data.data.file;
          const a = document.createElement("a");
          a.download = "客户信息查询和使用授权书模板";
          a.href = authFile;
          document.body.appendChild(a);
          a.click();
        } else {
          this.$notify({
            title: "失败",
            message: "下载失败",
            type: "warning",
          });
        }
      });
    },
    // 下载金融机构和新疆征信服务协议模板
    downloadDealFile() {
      let data = {
        enterpriseCode: "enterpriseCode",
        queryData: "",
        pdfLocation: "pdfLocation",
        enterpriseName: "enterpriseName",
        userName: "userName",
        orgCode: "orgCode",
      };
      this.$axios.post("/bank/getCreditBankPDF", data).then((res) => {
        if (res.data.data.file) {
          let authFile = "data:application/pdf;base64," + res.data.data.file;
          const a = document.createElement("a");
          a.download = "金融机构和新疆征信服务协议模板";
          a.href = authFile;
          document.body.appendChild(a);
          a.click();
        } else {
          this.$notify({
            title: "失败",
            message: "下载失败",
            type: "warning",
          });
        }
      });
    },

    // uploadCustomerInfoAndAuth() {
    //   this.$emit("uploadCustomerInfoAndAuth");
    // },
    // uploadFinanceDual() {
    //   this.$emit("uploadFinanceDual");
    // },

    //跳转至客户信息查询和使用授权书界面
    uploadCustomerInfoAndAuth() {
      this.$router.push("customerInfoAndAuth");
    },

    uploadFinanceDual() {
      this.$router.push("financeDual");
    },
    goProductList() {
      debugger
      // this.$emit("goProductList");
      this.$router.push('/conduct')
    },
    //成为开发者
    becomeDeveloper() {
      // if(this.userData.id == null){
      //     this.$notify({
      //         title: '提示',
      //         message: '请先登录',
      //         type: 'error'
      //     });
      // }else{
      this.$router.push("developer");
      // }
    },
    showpublicMessageclick() {
      console.log(this.$parent,'this.$parent');
      if (this.$router.currentRoute.path != "/") {
        debugger
        this.$router.push({
          path:"/",
          query:{
              toHome:1//1-公开信息
            }
          });
      } else {
        this.$emit("showpublicMessageclick");
      }
    },
    dialogVisibleShow() {
      this.$emit("dialogVisibleShow");
    },
    handleCommand() {
      this.$emit("handleCommand");
    },
    goUser() {
      this.$router.push("/usercenter");
    },
  },
};
</script>

<style scoped lang="less">
.top-header {
    min-width: 1200px;
    background-image: url("../../../src/assets/xinjiangImage/top-header-img.png");
    background-repeat:no-repeat;
    background-size: 100% 100%;
    height: 104px;

    &>div {
      width: 1200px;
      box-sizing: border-box;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 104px;
      .search-result-wrapper {
        width: 500px;
        display: inline-block;
        .el-input--medium .el-input__inner {
          height: 50px;
          line-height: 50px;
          font-size: 16px;
        }
        .el-input-group__append {
          background-color: #0060FF;
          color: #ffffff;
          font-size: 16px;
          padding: 0 30px;
          border: none;
        }
      }
      .platform-wrapper {
        width: 374px;
        height: 36px;
        img {
          width: 100%;
        }
      }
      .login-or-register{
        .login-register-btn {
          .el-button {
            font-size: 18px;
            font-weight: 400;
            color: #0060FF;
            padding: 8px 16px;
            border: 1px solid #0060FF;
          }
        }
    }
      .login-register-wrapper {
        display: flex;
        align-items: center;
        font-family: Source Han Sans CN;
        .avatar-wrapper.el-dropdown-selfdefine{
          font-size: 18px;
          font-weight: 400;
          // color: #0060FF;
          padding: 8px 16px;
        }
        .login-register-btn {
          margin-left: 40px;
          .el-button {
            font-size: 18px;
            font-weight: 400;
            color: #0060FF;
            padding: 8px 16px;
            border: 1px solid #0060FF;
          }
        }
        &>p{
          height: 100%;
          line-height: 104px;
          margin: 0;
          padding: 0 32px;
          font-size: 18px;
          font-weight: 400;
          color: #0060FF;
          cursor: pointer;
          &:hover {
            color: #ffffff;
            background: #0060FF;
          }
        }
        &>.avatar-container{
          height: 100%;
          line-height: 88px;
          // margin: 0;
          // padding: 0 32px;
          font-size: 18px;
          font-weight: 400;
          color: #0060FF;
          cursor: pointer;
          &:hover {
            color: #ffffff!important;
            background: #0060FF;
          }
        }
      }
    }
  }
.platform-wrapper {
  cursor: pointer;
}
</style>
