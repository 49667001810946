<template>
  <div class="home-page-wrapper">
    <maskLayer
      :mask="mask"
      class="maskLayer"
      v-if="showmaskLayer"
      @maskClick="maskClick"
    ></maskLayer>
    <top-header
      @restoreDefault="restoreDefault"
      :headerShow="headerShow"
      :userData="userData"
      @goProductList="goProductList"
      @showpublicMessageclick="showpublicMessageclick"
      @dialogVisibleShow="dialogVisibleShow"
      @handleCommand="handleCommand"
    ></top-header>

    <conductList v-if="showconductList" :userDataShowTop="true"></conductList>
    <div v-if="showpublicMessage && !showconductList" class="publicMessage">
      <div class="product-top-wrapper"></div>
      <div class="title">公开信息</div>
      <div class="publicMessageTop">
        <div class="topLeft">采集信用信息类别</div>
        <div class="topRight">
          市场主体登记信息；司法信息；经营信息；财务信息；资产及其担保信息，环保信息；信用信息；
          行政管理信息；授权方的法定代表人、董事、监事、高级管理人员与其履行职务相关的信息；
          相关部门、单位或其依法设立的组织机构在履职、提供服务中产生的授权方的相关信息；
          授权方有关的法律、行政法规、部门规章、地方性法规未禁止采集的其他信息。
        </div>
      </div>
      <div class="publicMessageCen">
        <div class="cenLeft">
          <div class="leftTitle">信用报告基本格式内容</div>
          <div class="cenBut">
            <!-- <span @click="downloadMuban">
                        下载模板
                    </span> -->
            <el-button
              @click="downloadMuban"
              type="primary"
              icon="el-icon-download"
              >下载模板</el-button
            >
          </div>
        </div>
        <div class="cenRight">
          <span>
            企业信用报告是全面记录企业各类经济活动，反映企业信用状况的文书。 </span
          ><br />
          <span>
            企业信用报告的内容主要包括：信息概要、基本信息、信贷记录明细、交易信用信息、公共信息、财务信息等。
          </span>
          <br />
          <span
            >信息概要对企业和业主获得的授信及负债情况、逾期及违约情况进行概要展示；
            基本信息包括企业名称、登记注册代码、注册地址，主要出资人名称、证件标识、出资金额占比等，
            高管人员基本信息，关联企业；信贷记录明细反映信息主体当前债务和历史债务信息；
            交易信用信息即公共事业缴费记录和企业与上下游供应商的贸易支付明细等；
            公共信息展示企业在公共部门记载正面和负面信息；财务信息指企业的资产负债、利润、现金流等信息。</span
          >
        </div>
      </div>
      <div class="publicMessageBot">
        <div class="botLeft">异议处理流程</div>
        <div class="botRight">
          <img src="../../../src/assets/public.png" alt="" />
        </div>
        <div class="botRight" style="margin-left: 50px;">
          <p style="color: #666;">如需提交征信异议，请联系新疆征信。</p>
          <p style="color: #666;">异议处理联系电话：0991-3532758。</p>
        </div>
      </div>
    </div>
    <div
      v-if="!showpublicMessage && !showconductList"
      class="home-search-wrapper"
    >
      <div>
        <div class="search-wrapper">
          <p class="search-title">新疆征信综合服务平台</p>
          <div
            class="search-input"
            :style="{ zIndex: zIndexT }"
            :class="{ active: mateResultIsShow }"
          >
            <!--                        <el-input size="medium" placeholder="请输入企业名称" v-model="search.companyName" @keyup.enter.native="goConductList" @input="getSearch" @focus="showSearch" @blur="hideSearch">-->
            <el-input
              size="medium"
              placeholder="请输入企业名称"
              v-model="search.companyName"
              @keyup.enter.native="goConductList"
            >
              <el-button @click="goConductList" slot="append">查一下</el-button>
            </el-input>

            <div class="mate-result-wrapper" v-if="mateResultIsShow">
              <div class="mate-result">
                <a v-for="(item, index) in companyList">{{ item }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="top-search">
          <p>
            热门搜索：<template v-for="(hotItem, index) in hotList"
              ><span class="hot" @click="quickSearch(index)">{{
                hotItem.companyName
              }}</span
              >&nbsp;&nbsp;&nbsp;&nbsp;</template
            >
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!showpublicMessage && !showconductList"
      class="xinjiang-service-wrapper"
    >
      <div class="basic-product-img-wrapper">
        <div>
          <div class="product-title">
            <span>基础产品</span>
            <span class="line"></span>
            <el-link
              :underline="false"
              class="check-more"
              @click="goProductList"
              >查看更多>></el-link
            >
          </div>
          <div>
            <div
              v-for="(item, index) in productList"
              :key="item.id"
              @click="goDetail(item)"
            >
              <img
                v-if="index == 0"
                src="../../../src/assets/xinjiangImage/basic-report-img.png"
                alt=""
              />
              <img
                v-if="index == 1"
                src="../../../src/assets/xinjiangImage/value-report-img.png"
                alt=""
              />
              <img
                v-if="index == 2"
                src="../../../src/assets/xinjiangImage/picture-img.png"
                alt=""
              />
              <img
                v-if="index == 3"
                src="../../../src/assets/xinjiangImage/credit-score-img.png"
                alt=""
              />
              <div class="product-text">
                <p style="width: 140px" v-if="index == 2">
                  {{ item.productName }}
                </p>
                <p v-else>{{ item.productName }}</p>
              </div>
              <div class="product-icon">
                <img
                  src="../../../src/assets/xinjiangImage/right-btn.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="solution-wrapper">
        <div>
          <div class="product-title">
            <span>解决方案</span>
            <span class="line"></span>
            <el-link
              :underline="false"
              class="check-more"
              @click="goProductList"
              >查看更多>></el-link
            >
          </div>
          <div class="common-style">
            <div v-for="(item, index) in schemeList" :key="item.id">
              <img
                v-if="index == 0"
                src="../../../src/assets/xinjiangImage/risk-img.png"
                alt=""
              />
              <img
                v-if="index == 1"
                src="../../../src/assets/xinjiangImage/finance-service-img.png"
                alt=""
              />
              <img
                v-if="index == 2"
                src="../../../src/assets/xinjiangImage/shopping-img.png"
                alt=""
              />
              <img
                v-if="index == 3"
                src="../../../src/assets/xinjiangImage/good-friend-img.png"
                alt=""
              />
              <div class="solution-text">
                <span>{{ item.productName }}</span>
                <div @click="goDetail(item)">
                  <img
                    src="../../../src/assets/xinjiangImage/right-arrow.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="solution-wrapper" style="margin-bottom: 180px">
        <div>
          <div class="product-title">
            <span>信用信息服务平台</span>
            <span class="line"></span>
            <el-link
              :underline="false"
              class="check-more"
              @click="goProductList"
              >查看更多>></el-link
            >
          </div>
          <div class="common-style">
            <div v-for="(item, index) in serverList" :key="item.id">
              <img
                v-if="index == 0"
                src="../../../src/assets/xinjiangImage/rural-area-img.png"
                alt=""
              />
              <img
                v-if="index == 1"
                src="../../../src/assets/xinjiangImage/company-img.png"
                alt=""
              />
              <img
                v-if="index == 2"
                src="../../../src/assets/xinjiangImage/local-finance-img.png"
                alt=""
              />
              <img
                v-if="index == 3"
                src="../../../src/assets/xinjiangImage/security-img.png"
                alt=""
              />
              <div class="solution-text">
                <span>{{ item.productName }}</span>
                <div @click="goDetail(item)">
                  <img
                    src="../../../src/assets/xinjiangImage/right-arrow.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot></foot>

    <dialogLogin @closeDialog="dialogFun" v-if="dialogVisible"></dialogLogin>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import range from "../../components/Range/range";
import dialogLogin from "@/components/dialogLogin/dialogLogin";
import maskLayer from "@/components/maskLayer/maskLayer";
import TopHeader from "@/components/header/TopHeader";
import foot from "@/components/footer/homeFooter";
import conductList from "../conduct/conductList.vue";
import {
  removeHeaderToken,
  getHeaderToken,
  getUserId,
  removeUserId,
} from "../../plugins/auth";
export default {
  name: "Home",
  components: {
    TopHeader,
    range,
    dialogLogin,
    foot,
    maskLayer,
    conductList,
  },
  data() {
    return {
      zIndex: 9,
      zIndexT: 1,
      showmaskLayer: false, //注册登录默认指引框
      showconductList: false,
      mask: {
        title: "若需要下载企业信用报告，请先登录!",
        showNext: true,
        maskTop: "50px",
        maskLeft: "950px",
        messtop: "50px",
        messleft: "1150px",
      },
      showpublicMessage: false,
      mateResultIsShow: false,
      companyList: [],

      searchList: [
        { id: 1, name: "查企业" },
        { id: 2, name: "查老板" },
      ],
      tabBarList: [
        { id: 1, name: "快捷登录" },
        { id: 2, name: "密码登录" },
      ],
      tabIndex: 0,
      changeTabItem: 0,
      dialogVisible: false,
      activeName: "first",
      value1: 0,
      rangeShow: false,
      password: "",
      headerShow: false,
      search: {
        companyName: "",
        pageNo: 1,
        pageSize: 20,
      },
      warnShow: false,
      //立即登录
      immediately: {
        phone: "",
        password: "",
      },
      tipsShow: false,
      tips: "",
      //快捷登录
      fast: {
        phone: "",
        code: "",
      },
      fastShow: false,
      fastTips: "获取验证码",
      fastCodeShow: false,
      userData: {
        headImg: null,
      },
      productList: [],
      schemeList: [],
      serverList: [],
      addList: [],
      hotList: [],
    };
  },
  watch: {
    // 监听showmaskLayer
    showmaskLayer: {
      handler(newName, oldName) {
        if (newName) {
          let body = document.getElementsByTagName("body");
          body[0].setAttribute("style", "overflow :hidden");
        } else {
          let body = document.getElementsByTagName("body");
          body[0].setAttribute("style", "overflow :auto");
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("user", { token: "token" }),
    ...mapGetters("user", { getToken: "getToken" }),
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to,from,this,'before')

    next((v) => {
      if (
        from.path == "/conductDetail" ||
        from.path == "/creditReport" ||
        from.path == "/relevance" ||
        from.path == "/grade" ||
        from.path == "/check" ||
        from.path == "/solution" ||
        from.path == "/retail" ||
        from.path == "/intellect" ||
        from.path == "/village" ||
        from.path == "/banking" ||
        from.path == "/online" ||
        from.path == "/local"
      ) {
        v.showpublicMessage = false;
        v.showconductList = true;
      }
    });
  },
  created() {
    this.getLocal();
    this.getProductList();
    this.$axios.get("/search/searchTop3CompanyName").then((res) => {
      this.hotList = res.data.data;
    });
    // 指引判断  maskLayer
    // 进入页面  先取值  没有  弹出指引存入maskLayer=true   有  不弹出指引
    // sessionStorage.setItem('maskLayer',JSON.stringify(false))
    // sessionStorage.setItem('maskLayerNum',JSON.stringify(0))
    let maskLayer = JSON.parse(sessionStorage.getItem("maskLayer"));
    let maskLayerNum = JSON.parse(sessionStorage.getItem("maskLayerNum"));
    // console.log(maskLayer,maskLayerNum,'maskLayer---maskLayerNum')
    if (maskLayer) {
      this.showmaskLayer = false;
    } else {
      if (maskLayerNum == null) {
        sessionStorage.setItem("maskLayerNum", JSON.stringify(1));
        this.showmaskLayer = true;
      }

      // 点击关闭 或者最后一步改为
      // sessionStorage.setItem('maskLayer',JSON.stringify(true))
    }

    if (this.$route.query.toHome == 1) {
      this.showpublicMessageclick();
    }

    console.log(this.$route.query, "tohome");
  },
  activated() {
    this.getLocal("a");
  },
  mounted() {},
  methods: {
    // 点击图标  恢复默认
    restoreDefault() {
      this.showpublicMessage = false;
      this.showconductList = false;
    },
    // 点击公开信息
    showpublicMessageclick() {
      debugger;
      this.showpublicMessage = true;
      this.showconductList = false;
    },
    // 点击指引框
    maskClick(v) {
      if (v.type == "close") {
        // 关闭指引框
        this.showmaskLayer = false;
        // sessionStorage.setItem('maskLayer',JSON.stringify(true))
      } else {
        sessionStorage.setItem("maskLayerNum", JSON.stringify(2));
        this.zIndex = 1;
        this.zIndexT = 9;
        this.mask = {
          title: "登录后，您可以在此查询公司，查询后方可下载公司信用报告!",
          showNext: false,
          maskTop: "400px",
          maskLeft: "750px",
          messtop: "400px",
          messleft: "950px",
        };
      }
    },
    downloadMuban() {
      if (process.env.NODE_ENV === "development") {
        axios
          .get("/common/download?fileName=creditReport.pdf&delete=" + false, {
            // baseURL:`./se`,//测试
            baseURL: `./cdevservice`, //生产
            headers: {
              "Content-Type": "",
            },
            responseType: "blob",
            timeout: 300 * 1000,
          })
          .then((res) => {
            if (res.data) {
              let url = window.URL.createObjectURL(
                new Blob([res.data], { type: "application/pdf" })
              );
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.setAttribute("download", "信用报告基本格式模板.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            } else {
              this.$notify({
                title: "失败",
                message: "下载失败",
                type: "warning",
              });
            }
          });
      } else {
        axios
          .get("/common/download?fileName=creditReport.pdf&delete=" + false, {
            // baseURL:`./se`,//测试
            baseURL: `./cdevservice`, //生产
            headers: {
              "Content-Type": "",
            },
            responseType: "blob",
            timeout: 300 * 1000,
          })
          .then((res) => {
            if (res.data) {
              let url = window.URL.createObjectURL(
                new Blob([res.data], { type: "application/pdf" })
              );
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.setAttribute("download", "信用报告基本格式模板.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              window.URL.revokeObjectURL(url);
            } else {
              this.$notify({
                title: "失败",
                message: "下载失败",
                type: "warning",
              });
            }
          });
      }
    },
    getSearch() {
      if (this.search.companyName) {
        this.getCompanyList();
      } else {
        this.mateResultIsShow = false;
      }
    },
    showSearch() {
      if (this.search.companyName) {
        this.getCompanyList();
      }
    },
    hideSearch() {
      this.mateResultIsShow = false;
    },
    getCompanyList() {
      let _this = this;
      _this.mateResultIsShow = true;
      setTimeout(function () {
        _this.companyList = [
          "乌鲁木齐文化旅游股份有限公司",
          "新疆股权交易中心股份有限公司",
          "新疆循环发展融资担保有限公司",
          "新疆金融控股集团有限公司",
          "新疆财政厅",
        ];
      }, 200);
    },

    // 下载客户信息查询和使用授权书模板
    downloadAuthFile() {
      let data = {
        enterpriseCode: "enterpriseCode",
        queryData: "",
        pdfLocation: "pdfLocation",
        enterpriseName: "enterpriseName",
        userName: "userName",
        orgCode: "orgCode",
      };

      this.$axios.post("/bank/getBankEntPDF", data).then((res) => {
        if (res.data.data.file) {
          let authFile = "data:application/pdf;base64," + res.data.data.file;
          const a = document.createElement("a");
          a.download = "客户信息查询和使用授权书模板";
          a.href = authFile;
          document.body.appendChild(a);
          a.click();
        } else {
          this.$notify({
            title: "失败",
            message: "下载失败",
            type: "warning",
          });
        }
      });
    },
    // 下载金融机构和新疆征信服务协议模板
    downloadDealFile() {
      let data = {
        enterpriseCode: "enterpriseCode",
        queryData: "",
        pdfLocation: "pdfLocation",
        enterpriseName: "enterpriseName",
        userName: "userName",
        orgCode: "orgCode",
      };
      this.$axios.post("/bank/getCreditBankPDF", data).then((res) => {
        if (res.data.data.file) {
          let authFile = "data:application/pdf;base64," + res.data.data.file;
          const a = document.createElement("a");
          a.download = "金融机构和新疆征信服务协议模板";
          a.href = authFile;
          document.body.appendChild(a);
          a.click();
        } else {
          this.$notify({
            title: "失败",
            message: "下载失败",
            type: "warning",
          });
        }
      });
    },
    //跳转至客户信息查询和使用授权书界面
    uploadCustomerInfoAndAuth() {
      this.$router.push("customerInfoAndAuth");
    },

    uploadFinanceDual() {
      this.$router.push("financeDual");
    },

    changeList(index) {
      // console.log(index)
      this.tabIndex = index;
    },
    dialogVisibleShow() {
      // 点击登录   遮罩消失
      this.showmaskLayer = false;
      this.dialogVisible = true;
    },
    changeTab(index) {
      // console.log(index)
      this.changeTabItem = index;
    },
    quickSearch(index) {
      this.search.companyName = this.hotList[index].companyName;
      this.goConductList();
    },
    goConductList() {
      if (this.search.companyName == "") {
        this.$message("请输入需要查询的关键字");
      } else {
        this.$axios.post("/search/business", this.search).then((res) => {
          if (res.data.code == 200 && res.data.data.code == 200) {
            let searchResult = JSON.stringify(res.data.data);
            window.sessionStorage.setItem("searchResult", searchResult);
            this.$router.push({
              path: "/company",
              query: { searchKeyword: this.search.companyName },
            });
          } else {
            this.$notify({
              title: "失败",
              message: "查询失败,请重新输入关键字查询",
              type: "error",
            });
          }
        });
      }
    },
    goProductList() {
      this.showconductList = true;
      this.$router.push("/conduct");
    },
    goUser() {
      this.$router.push("/usercenter");
    },
    getLocal(v) {
      if (getHeaderToken()) {
        // 已经登录
        if (v == "a") {
        } else {
          this.showmaskLayer = false;
          sessionStorage.setItem("maskLayer", JSON.stringify(true));
        }
        this.headerShow = false;
        this.$axios.get("/user/annualById?id=" + getUserId()).then((res) => {
          this.userData = res.data.data;
          // 登录成功 判断是否需要指引  区分是否dwenglc
          let maskLayer = JSON.parse(sessionStorage.getItem("maskLayer"));

          let maskLayerNum = JSON.parse(sessionStorage.getItem("maskLayerNum"));
          // console.log(maskLayer,maskLayerNum,'maskLayer存的2')

          if (maskLayer) {
            this.showmaskLayer = false;
          } else {
            if (maskLayerNum == 1) {
              this.showmaskLayer = true;
              this.zIndex = 1;
              this.zIndexT = 9;
              this.mask = {
                title:
                  "登录后，您可以在此查询公司，查询后方可下载公司信用报告!",
                showNext: false,
                maskTop: "400px",
                maskLeft: "750px",
                messtop: "400px",
                messleft: "950px",
              };
              sessionStorage.setItem("maskLayerNum", JSON.stringify(2));
            }
          }
        });
      } else {
        // 未登录
        // 登录成功 判断是否需要指引  区分是否dwenglc
        // let maskLayer = JSON.parse(sessionStorage.getItem('maskLayer'))
        //     sessionStorage.setItem('maskLayerNum',JSON.stringify(2))
        //     let maskLayerNum = JSON.parse(sessionStorage.getItem('maskLayerNum'))
        //     console.log(maskLayer,maskLayerNum,'maskLayer存的2')
        //     if(maskLayer){
        //     this.showmaskLayer = false;
        //     }else{
        //         if(maskLayerNum==1){
        //           this.showmaskLayer = true;
        //             this.zIndex = 1;
        //             this.zIndexT = 9;
        //             this.mask={
        //                 title:'登录后，您可以在此查询公司，查询后方可下载公司信用报告!',
        //                 showNext:false,
        //                 maskTop:'400px',
        //                 maskLeft:'950px',
        //                 messtop: '400px',
        //                 messleft: '1150px',
        //             }
        //         }

        //     }
        this.userData = { headImg: null };
        this.headerShow = true;
      }
    },
    //产品列表
    getProductList() {
      let data = {
        productStatus: "1",
      };
      let productListArr = [];
      let schemeListArr = [];
      let serverListArr = [];
      this.$axios
        .post("/product/getProduct", data)
        .then((res) => {
          if (res.data.code == 200) {
            let list = res.data.data;
            list.forEach((item, index) => {
              if (item.type == 1) {
                // this.productList.push(item)
                productListArr.push(item);
              } else if (item.type == 2 && item.id !== "10") {
                // this.schemeList.push(item)
                schemeListArr.push(item);
              } else if (item.type == 3) {
                // this.serverList.push(item)
                serverListArr.push(item);
              } else {
                this.addList.push(item);
              }
            });
            this.productList =
              productListArr.length > 4
                ? productListArr.slice(0, 4)
                : productListArr;
            this.schemeList =
              schemeListArr.length > 4
                ? schemeListArr.slice(0, 4)
                : schemeListArr;
            this.serverList =
              serverListArr.length > 4
                ? serverListArr.slice(0, 4)
                : serverListArr;
            // this.productList.pop()
          }
        })
        .catch((err) => {
          // console.log(err)
        });
    },
    goDetail(item) {
      if (item.productName == "黑名单") {
        this.$router.push("conductDetail");
      }
      if (
        item.productName == "企业基础版信用报告" ||
        item.productName == "企业增值版信用报告"
      ) {
        this.$router.push("creditReport");
      }
      if (item.productName == "中小企业关联图谱") {
        this.$router.push("relevance");
      }
      if (item.productName == "信用评分") {
        this.$router.push("grade");
      }
      if (item.productName == "风险监测及预警") {
        this.$router.push("check");
      }
      if (item.productName == "科创企业融资解决方案") {
        this.$router.push("solution");
      }
      if (item.productName == "零售消费信贷智能决策解决方案") {
        this.$router.push("retail");
      }
      if (item.productName == "智能信贷获客系统") {
        this.$router.push("intellect");
      }
      if (item.productName == "农村信用信息共享服务平台") {
        this.$router.push("village");
      }
      if (item.productName == "中小微企业综合金融服务平台") {
        this.$router.push("banking");
      }
      if (item.productName == "地方金融聚信服务平台") {
        this.$router.push("local");
      }
      if (item.productName == "线上银担直联服务平台") {
        this.$router.push("online");
      }
    },
    goShouYe() {
      this.$router.push("/");
    },
    //成为开发者
    becomeDeveloper() {
      // if(this.userData.id == null){
      //     this.$notify({
      //         title: '提示',
      //         message: '请先登录',
      //         type: 'error'
      //     });
      // }else{
      this.$router.push("developer");
      // }
    },
    dialogFun(val) {
      this.dialogVisible = val;
      this.getLocal("a");
    },
    //退出登录
    handleCommand() {
      this.$confirm("您确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          removeHeaderToken();
          removeUserId();
          this.getLocal();
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
// .home-page-wrapper{
//     .maskLayer{
//         height: 100%;
//     }
// }
.platform-wrapper {
  cursor: pointer;
}
.publicMessage {
  border: 1px solid #fff;
  width: 80%;
  margin-left: 10%;
  // padding: 10px;
  // padding-top: 0;
  background-color: #fcfdff;
  background: linear-gradient(#e4ecfe, #fcfdff);
  .product-top-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    min-width: 1200px;
    background-image: url(../../assets/xinjiangImage/credit-value.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 200px 0;
  }
  .title {
    margin-top: 410px;
    font-size: 28px;
    color: #000;
    // font-weight: 600;
    text-align: center;
  }
  .publicMessageTop {
    // display: flex;
    // align-items: center;
    // box-shadow: 1px 1px 1px #ccc;
    font-size: 16px;
    .topLeft {
      // height: 100%;
      // flex: 1;
      // font-size: 22px;
      font-weight: bold;
      padding: 15px 10px 0px 10px;
      margin: 0 20px;
      display: flex;
      align-items: center;
      color: #666;
      // border: 2px solid #000;
      // border-radius: 3px ;
      &::before {
        content: "";
        width: 4px;
        height: 18px;
        display: inline-block;
        background-color: #3379ff;
        // border: 5px solid #000;
        // border-radius: 2px;
        margin-right: 5px;
      }
    }
    .topRight {
      flex: 14;
      padding: 5px 10px 10px 10px;
      margin: 0 20px;

      color: #666;
      line-height: 30px;
      text-indent: 32px;
    }
  }
  .publicMessageCen {
    // display: flex;
    // align-items: center;
    // margin: 20px 0;
    // box-shadow: 1px 1px 1px #ccc;
    font-size: 16px;
    .cenLeft {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 10px 0px 10px;
      .leftTitle {
        // height: 100%;
        // flex: 1;
        // font-size: 22px;
        font-weight: bold;
        // padding: 10px;
        display: flex;
        align-items: center;
        color: #666;
        margin: 0 20px;
        // border: 2px solid #000;
        // border-radius: 3px ;
        &::before {
          content: "";
          width: 4px;
          height: 18px;
          display: inline-block;
          background-color: #3379ff;
          // border: 5px solid #000;
          // border-radius: 2px;
          margin-right: 5px;
        }
      }
      .cenBut {
        span {
          // width: auto;
          margin-left: 10px;
          border: 2px solid #3379ff;
          color: #3379ff;
          // background-color: #3379ff;
          border-radius: 5px;
          padding: 2px 5px;
          cursor: pointer;
        }
      }
    }
    .cenRight {
      flex: 12;
      padding: 5px 10px 10px 10px;
      margin: 0 20px;
      color: #666;
      line-height: 30px;
      span {
        display: inline-block;
        text-indent: 32px;
      }
    }
    // .cenBut{
    //     flex: 2;
    //     span{
    //         // width: auto;
    //         margin-left: 10px;
    //         border: 2px solid  #3379ff;
    //         color: #3379ff;
    //         // background-color: #3379ff;
    //         border-radius: 5px;
    //         padding: 2px 5px;
    //         cursor: pointer;
    //     }
    // }
  }
  .publicMessageBot {
    // display: flex;
    // align-items: center;
    // box-shadow: 1px 1px 1px #ccc;
    font-size: 16px;
    .botLeft {
      // height: 100%;
      // flex: 1;
      // font-size: 22px;
      font-weight: bold;
      padding: 15px 10px 0px 10px;
      margin: 0 20px;
      color: #666;
      display: flex;
      align-items: center;
      // border: 2px solid #000;
      // border-radius: 3px ;
      &::before {
        content: "";
        width: 4px;
        height: 18px;
        display: inline-block;
        background-color: #3379ff;
        // border: 5px solid #000;
        // border-radius: 2px;
        margin-right: 5px;
      }
    }
    .botRight {
      // flex: 14;
      img {
        width: 100%;
      }
    }
  }
}
</style>
