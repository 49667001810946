<template>
  <div class="dialogLogin" @mousewheel.prevent>
    <div class="contentC">
      <div class="left">
        <img src="../../../public/static/image/login.png" />
      </div>
      <div class="right">
        <img @click="closeDialog" src="../../../public/static/image/close.png" />
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="密码登录" name="first">
            <div>
              <div class="rightInput" style="border-radius: 4px">
                <p style="color: #2b2b2b">中国+86 &nbsp;&nbsp;|</p>
                <input @blur="checkImmediatelyPhone" v-model="immediately.phone" placeholder="请输入号码" type="text" />
              </div>
              <p v-if="warnShow" style="
                  display: block;
                  margin-block-start: 0;
                  margin-block-end: 0;
                  margin-inline-start: 0px;
                  margin-inline-end: 0px;
                  color: red;
                ">
                手机号格式不正确,请重新输入
              </p>
              <el-input v-model="immediately.password" placeholder="请输入密码" show-password></el-input>
              <p v-if="tipsShow" style="
                  display: block;
                  margin-block-start: 0;
                  margin-block-end: 0;
                  margin-inline-start: 0px;
                  margin-inline-end: 0px;
                  color: red;
                ">
                {{ tips }}
              </p>
              <div @click="login" class="loginBtn">立即登录</div>
              <Vcode :show="isShow" @success="onSuccess" @close="onClose" />
              <p class="zc" @click="goregistration" style="
                  cursor: pointer;
                  float: right;
                  padding-right: 10px;
                  font-size: 14px;
                ">
                用户注册
              </p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="短信登录" name="second">
            <div>
              <div class="rightInput" style="border-radius: 4px">
                <p style="color: #2b2b2b">中国+86 &nbsp;&nbsp;|</p>
                <input @blur="checkImmediatelyPhone" v-model="immediately.phone" placeholder="请输入号码" type="text" />
              </div>
              <p v-if="warnShow" style="
                  display: block;
                  margin-block-start: 0;
                  margin-block-end: 0;
                  margin-inline-start: 0px;
                  margin-inline-end: 0px;
                  color: red;
                ">
                手机号格式不正确,请重新输入
              </p>
              <div style="display: flex;">
                <el-input v-model="immediately.password" placeholder="请输入验证码"></el-input>
                <el-button type="primary" @click="sendSMS" :disabled="isNum">
                  {{ isNum? number : text }}
                </el-button>
              </div>
              
              <div @click="login2" class="loginBtn">立即登录</div>
              <Vcode :show="isShow" @success="onSuccess" @close="onClose" />
              <p class="zc" @click="goregistration" style="
                  cursor: pointer;
                  float: right;
                  padding-right: 10px;
                  font-size: 14px;
                ">
                用户注册
              </p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {
  removeHeaderToken,
  setHeaderToken,
  setUserId,
} from "../../plugins/auth";
import { isMobile } from "../../plugins/validate";
// import range from "../Range/range";
import Vcode from "vue-puzzle-vcode";
import util from "../../plugins/util";
export default {
  name: "dialogLogin",
  components: {
    // range,
    Vcode,
  },
  data() {
    return {
      code:"",
      time:null,
      isNum:false,
      text:"发送短信",
      number:120,
      activeName: "first",
      selectList: [],
      areaList: {
        label: "name",
        value: "id",
        children: "childrens",
      },
      options: [],
      tabBarList: [
        { id: 1, name: "密码登录" },
        { id: 2, name: "快捷登录" },
      ],
      changeTabItem: 1,
      fast: {},
      immediately: {},
      fastShow: false,
      fastCodeShow: false,
      warnShow: false,
      tipsShow: false,
      tips: "",
      fastTips: "获取验证码",
      dialogVisible: false,
      isShow: false,
    };
  },
  mounted() {
    this.getCity();
  },
  methods: {
    sendSMS(){
      console.log("发送短信");
      this.isNum = !this.isNum;
      if(this.isNum){
        this.time = setInterval(()=>{
          if(this.number === 1) {
            clearInterval(this.time);
            this.isNum = false;
            this.number = 120;
          }
          this.number = this.number - 1;
        },1000)
      }
      this.$axios
        .get(`/user/sendPhoneMsgLogin?phone=${this.immediately.phone}`).then((res) => {
          this.code = res.data.msg;
        })
    },
    login2(){
      this.isShow = true;   
    },
    changeTab(index) {
      // console.log(index);
      this.changeTabItem = index;
    },
    //快捷登录验证手机号码
    checkFastPhone() {
      if (isMobile(this.fast.phone)) {
        this.fastShow = false;
      } else {
        this.fastShow = true;
      }
    },
    //密码登录验证手机号码
    checkImmediatelyPhone() {
      if (isMobile(this.immediately.phone)) {
        this.warnShow = false;
      } else {
        this.warnShow = true;
      }
    },
    //发送验证码
    sendCode() {
      if (this.fastTips == "获取验证码") {
        this.isShow = true;
      }
    },
    onSuccess(msg) {
      this.isShow = false; // 通过验证后，需要手动关闭模态框
      // 密码登入
      if (isMobile(this.immediately.phone)) {
        this.warnShow = false;
        this.$axios
          .post("/user/login2", {
            username: this.immediately.phone,
            password: util.encrypt(this.immediately.password),
            code: this.code,
            verifyCode: this.immediately.password
          })
          .then((res) => {
            // console.log(res)
            if (res.data.code == 200) {
              removeHeaderToken();
              setHeaderToken(res.data.token);
              this.$axios.get("/user/getInfo").then((response) => {
                setUserId(response.data.user.id);
                this.$notify({
                  title: "成功",
                  message: "登陆成功",
                  type: "success",
                });
                this.closeDialog();
              });
            } else {
              this.tipsShow = true;
              this.tips = res.data.msg;
            }
          })
          .catch((err) => {});
      } else {
        this.warnShow = true;
      }
    },
    onClose() {
      this.isShow = false;
    },
    //密码登录
    login() {
      this.isShow = true;
    },
    goForgetPwd() {
      this.$router.push("forgetPwd");
    },
    goregistration() {
      this.$router.push("registration");
    },
    closeDialog() {
      let dialogVisible = false;
      this.$emit("closeDialog", dialogVisible);
    },
    //获取城市
    getCity() {
      this.$axios.get("/dictionary/CityTree").then((res) => {
        if (res.data.code == 200) {
          this.options = res.data.data;
        }
      });
    },
    handleClick() {
      if(this.activeName === "second"){
        this.immediately.password = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-button{
  width: 200px;
}
/deep/ .el-tabs {
  margin-top: 20px;
}
.dialogLogin {
  background: rgba(0, 0, 0, 0.5);
  background-size: 100% 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 14;
  //padding-top: 10vh;
  overflow: hidden;

  .contentC {
    position: relative;
    top: 15% !important;
    left: 50%;
    transform: translateX(-50%);
    width: 760px;
    height: 480px;
    background: #ffffff;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
    z-index: 55;
    display: flex;

    //align-items: center;
    .left {
      width: 40%;
      //height: 100%;
      background: #128bed;

      img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        left: 12%;
        width: 74%;
        height: 54%;
      }
    }

    .right {
      box-sizing: content-box;
      width: 65%;
      //height: 100%;
      padding: 20px;

      .con {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding-left: 60px;
        padding-right: 60px;

        .tab {
          width: 50%;
          height: 100%;
          line-height: 60px;
          text-align: center;
          font-family: PingFangSC-Regular;
          font-size: 20px;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      img {
        width: 16px;
        height: 16px;
        float: right;
        cursor: pointer;
      }
    }
  }
}

.activeItemTabBar {
  width: 50%;
  height: 100%;
  line-height: 60px;
  text-align: center;
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #128bed;
  letter-spacing: 0;
  font-weight: 400;
  border-bottom: 1px solid #128bed;
}

.rightInput {
  width: 99%;
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid #e7e7e7;
  text-indent: 10px;
  margin-top: 30px;

  input {
    height: 100%;
    width: 70%;
    line-height: 48px;
    background: none;
    outline: none;
    border: none;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    //color: #BFBFBF;
    letter-spacing: 0;
    font-weight: 400;
    text-indent: 20px;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  border: 1px solid #e7e7e7;

  input {
    height: 100%;
    width: 60%;
    line-height: 48px;
    background: none;
    outline: none;
    border: none;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    //color: #BFBFBF;
    letter-spacing: 0;
    font-weight: 400;
    text-indent: 20px;
  }

  p {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #128bed;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 10px;
    width: 40%;
    cursor: pointer;
    text-align: center;
  }
}

.loginBtn {
  width: 100%;
  height: 48px;
  background: #128bed;
  margin-top: 20px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 400;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}

.loginText {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #777777;
  letter-spacing: 0;
  font-weight: 400;
  text-align: center;
}

.dialogLogin .el-input__inner {
  height: 48px;
  width: 100%;
  line-height: 48px;
}
.zc:hover {
  color: #128bed;
}
</style>
