<template>
  <div>
    <div v-loading="g_loading" style="height:100vh;">
      <Relation-Graph ref="seeksRelationGraph" :options="graphOptions" :on-node-expand="onNodeExpand" :on-node-collapse="onNodeCollapse"/>
    </div>
  </div>
</template>

<script>
  import RelationGraph from 'relation-graph'
  // import { getList,listLeaf } from "@/api/login";

  export default {
    name: "Login",
    components: {
      RelationGraph
    },

    data() {
      return {
        g_loading: true,
        nodesListJson:[],
        linksListJson:[],
        currentCase: '双向树',
        isShowCodePanel: false,
        graphOptions: {
          'backgrounImageNoRepeat': true,
          'layouts': [{
            'label': '中心',
            'layoutName': 'tree',
            'layoutClassName': 'seeks-layout-center',
            'defaultJunctionPoint': 'border',
            'from': 'left',
            'defaultNodeShape': 0,
            'defaultLineShape': 1,
            'max_per_width': '320',//控制节点间线条长度
            'min_per_width': '150',
            'min_per_height': '65',//控制节点间的高度
            'max_per_height': '75',
            'distance_coefficient': 100,
            "isMoveByParentNode": false,
            'centerOffset_x': 560,

          }],
          'defaultLineMarker': {
            'markerWidth': 12,
            'markerHeight': 12,
            'refX': 6,
            'refY': 6,
            'data': 'M2,2 L10,6 L2,10 L6,6 L2,2'
          },
          'defaultNodeShape': 1,
          'defaultLineShape': 4,
          'defaultJunctionPoint': 'lr',
          'defaultNodeBorderWidth': 1,
          "defaultNodeColor": "rgba(82, 85, 80, 0.22)",
          "defaultNodeFontColor": "rgba(37, 35, 35, 1)",
          "isMoveByParentNode": false,
          "disableDragNode": false,
          moveToCenterWhenRefresh: false,
          'defaultExpandHolderPosition': 'right',
          'defaultNodeWidth': '200',
        },

        info:{}
      };
    },

    mounted() {
      this.showSeeksGraph()
    },
    methods: {
      showSeeksGraph() {
        console.log("在加载中");
        this.g_loading = true;
        this.info = JSON.parse(window.sessionStorage.getItem("obj"));
        // console.log(this.info)
        let entName;
        if(this.info){
          entName = this.info.XY010101
        }else {
          entName = this.$route.query.entname
        }
        let data = {
          entname: entName
          // entname:'西安蓝溪科技投资控股有限公司'
        }

        this.$axios.post("/bank/relationList", data).then(res => {
          this.g_loading = false;
          this.nodesListJson = res.data.data.nodesListJson;
          this.linksListJson = res.data.data.linksListJson;
          // this.getGraph();
        })

      },

      getGraph() {
        var __graph_json_data = {
          "rootId": "a",
          "nodes": eval(this.nodesListJson),
          "links": eval(this.linksListJson),
          "levelDistance": '400,200,300,500'
        }
        if(__graph_json_data.nodes.length == 0 || __graph_json_data.links.length == 0){
          this.$message("暂无数据！");
          return ;
        }
        if(__graph_json_data.nodes[0]){
          __graph_json_data.nodes[0].height = '60'
          __graph_json_data.nodes[0].width = '220'
          __graph_json_data.nodes[0].color = '#e6a23c'
          __graph_json_data.nodes[0].fontColor = '#ffffff'
        }
        __graph_json_data.nodes.forEach(thisNode => {
          if (thisNode.leaf == '0' && thisNode.expandHolderPosition == 'left') {
            thisNode.data = {
              asyncChild: true,
              loaded: false
            } // 这是一个自定义属性，用来在后续判断如果点击了这个节点，则动态获取数据
            thisNode.expandHolderPosition = 'left'
            thisNode.expanded = false
          }

          if (thisNode.leaf == '0' && thisNode.expandHolderPosition == 'right') {
            thisNode.data = {
              asyncChild: true,
              loaded: false
            } // 这是一个自定义属性，用来在后续判断如果点击了这个节点，则动态获取数据
            thisNode.expandHolderPosition = 'right'
            thisNode.expanded = false
          }
        })
        // 以上数据中的node和link可以参考"Node节点"和"Link关系"中的参数进行配置
        this.$refs.seeksRelationGraph.setJsonData(__graph_json_data, (seeksRGGraph) => {
          // Called when the relation-graph is completed
        })
      },
      onNodeCollapse(){
        this.$refs.seeksRelationGraph.refresh();
      },

      onNodeExpand(node, e) {
        var nodeWidth = Number(node.x) + Number(node.width);
        var map = {
          id: node.id,
          text: node.text,
          expandHolderPosition: node.expandHolderPosition
        };
        //模拟动态加载数据
        if (node.data && node.data.asyncChild === true && node.data.loaded === false) {
          this.g_loading = true
          node.data.loaded = true
          var nodesData;
          var linksData;

          this.$axios.post("/bank/relationListLeaf", map).then(res => {
            this.g_loading = false
            nodesData = res.data.data.nodesList;
            linksData = res.data.data.linksList;
            var _new_json_data = {
              nodes: eval(nodesData),
              links: eval(linksData)
            }
            if(_new_json_data.nodes.length ==0){
              this.$message("该节点暂无子节点可展示！");
              return ;
            }
            _new_json_data.nodes.forEach(thisNode => {
              if (thisNode.leaf == '0' && thisNode.expandHolderPosition == 'left') {
                thisNode.data = {
                  asyncChild: true,
                  loaded: false
                } // 这是一个自定义属性，用来在后续判断如果点击了这个节点，则动态获取数据
                thisNode.expandHolderPosition = 'left'
                thisNode.expanded = false
              }

              if (thisNode.leaf == '0' && thisNode.expandHolderPosition == 'right') {
                thisNode.data = {
                  asyncChild: true,
                  loaded: false
                } // 这是一个自定义属性，用来在后续判断如果点击了这个节点，则动态获取数据
                thisNode.expandHolderPosition = 'right'
                thisNode.expanded = false
              }
              thisNode.x = nodeWidth;
            })
            this.$refs.seeksRelationGraph.appendJsonData(_new_json_data, true, (seeksRGGraph) => {
              // 这些写上当图谱初始化完成后需要执行的代码
              this.$refs.seeksRelationGraph.refresh()
            })
          })

        }else{
          this.$refs.seeksRelationGraph.refresh()
        }
      }
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss">

</style>
